@import url('./fonts/font.css');

body {
  -webkit-user-select: none;
  -moz-user-select: none;   
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: black;
}

.header-text {
    text-align: center;
    font-family: 'WaltographFont';
    color: white;
}

.instructions-text {
    font-size: 18px;
    text-align: center;
    color: white;
}

.disney-text {
  font-size: 86px;
  margin: 25px;
}

.disney-subtext {
  font-size: 60px;
  margin: 25px;
}

.disney-subsubtext {
  font-size: 43px;
  margin: 25px;
}

@media screen and (max-width: 1200px) {
  .disney-text {
      font-size: 72px;
  }

  .disney-subtext {
      font-size: 50px;
  }

  .disney-subsubtext {
      font-size: 36px;
  }
}
@media screen and (max-width: 900px) {
  .disney-text {
      font-size: 60px;
  }

  .disney-subtext {
      font-size: 40px;
  }

  .disney-subsubtext {
      font-size: 30px;
  }
}
@media screen and (max-width: 600px) {
  .disney-text {
      font-size: 48px;
  }

  .disney-subtext {
      font-size: 32px;
  }

  .disney-subsubtext {
      font-size: 24px;
  }
}

#name-field {
    font-size: 1.15rem;
    padding: 0.5rem 1rem;
    margin: 25px;
    width: 300px;
    background-color: white;
    color: black;
    caret-color: black;
    border: none;
    border: 2px solid black;
    border-radius: 5px;
}

#name-field:focus {
  outline: none;
  border-color: gray;
}

#name-field::selection {
  background: lightgray;
}

#action-button {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    margin: 15px;
    width: 200px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}